import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,
  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import HeroMobile from "./../../images/Liporedukt-mobile.jpg"
import HeroTablet from "./../../images/Liporedukt-tablet.jpg"
import HeroDesktop from "./../../images/Liporedukt-desktop.jpg"
import HeroXL from "./../../images/Liporedukt-desktop-xl.jpg"
import Feature1 from "./../../images/liporedukt-feature.jpg"
import Feature4 from "./../../images/prost-psor-plus-herbal-guarantee.jpg"

const LiporeduktPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "LiporeduktEn" }}>
    <Seo title="Liporeduct" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>LIPOREDUCT</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={HeroXL} media="(min-width: 1200px)" />
            <source srcSet={HeroDesktop} media="(min-width: 992px)" />
            <source srcSet={HeroTablet} media="(min-width: 767px)" />
            <source srcSet={HeroMobile} media="(min-width: 370px)" />
            <img
              srcSet={HeroMobile}
              alt="Liporeduct"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Liporeduct
          </h5>
          <h1 className="display-4 font-light">For a balanced weight</h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">Liporeduct</h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            For a balanced weight
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Liporeduct is an all-natural product for maintaining a healthy and
            balanced weight.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/ena/liporedukt-for-problems-with-overweight"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Buy online at <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Available in pharmacies across the country</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>solvent free</li>
                <li>preservatives free</li>
                <li>gluten free</li>
                <li>sugar free</li>
                <li>GMO-free</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Recipe for a balanced weight</h2>
        </Col>
      </Row>
      <Row className="product-container mb-4">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Recipe for a balanced weight"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Liporeduct is a natural product that helps to reduce weight and
            maintain it at healthy levels. It helps regulate metabolism. The
            complex of herbs - pepper, buckthorn and senna, has a positive
            effect on chronic constipation, lazy intestines and gas. Thunderbolt
            roots help reduce excess weight.
          </p>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Guarantee for quality and safety</h2>
        </Col>
      </Row>
      <Row className="product-container mb-4">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Guarantee for quality and safety"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Standardized plant extracts are obtained from plants or parts
            thereof by extraction of biologically active components. Their
            advantage is that the type of active substances can be controlled
            and maintained in a certain quantity and quality.
          </p>
          <p>
            Taking an extract with a proven effect ensures optimal impact on
            body functions. The plant extracts included in Liporeduct complement
            each other's beneficial effects.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Additional information</h5>
        </Col>
      </Row>
      <Row className="mb-4 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Contents
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Pepper stalks</strong> (Herba Polygonum hydropiper)
                    49,5 mg
                  </p>
                  <p>
                    <strong>Buckthorn bark</strong> (Cortex Erhamni frangulae)
                    49,5 mg
                  </p>
                  <p>
                    <strong>Senna leaves</strong> (Folia Senna) 49,5 mg
                  </p>
                  <p>
                    <strong>Thunder root</strong> (Radix Ononidis) 49,5 mg
                  </p>
                  <p>
                    <strong>Queen stalk</strong> (Herba Alchemilla vulgaris)
                    33,0 mg
                  </p>
                  <p>
                    <strong>Rhubarb roots</strong> (Radix Reven) 33,0 mg
                  </p>
                  <p>
                    <strong>Fennel seeds</strong> (Fructus Foeniculi) 33,0 mg
                  </p>
                  <p>
                    <strong>Bearberry leaves</strong> (Folia Uve ursi) 33,0 mg
                  </p>

                  <p>Excipients: maltodextrin, magnesium stearate</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Intake
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>2 times daily, 1-2 tablets after meals</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Contraindications
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    None identified. Do not exceed the recommended daily dose.
                    Do not use the product as a substitute for a varied diet.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Effects
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Liporeduct is a natural product that helps to reduce weight
                    and maintain it at healthy levels. It has a positive effect
                    on weight control by supplementing food intake, helping to
                    regulate metabolism and supporting the stimulation of
                    intestinal function.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default LiporeduktPageEn
